import Image from '@/components/common/image/Image';

type PageBanner = {
  url: string;
  alt: string;
};

const PageBanner = ({ url, alt }: PageBanner) => (
  <div className='flex justify-center mb-4 lg:mb-12'>
    <Image
      src={url}
      alt={alt}
      width={1920}
      height={600}
      priority
      sizes='100vw'
    />
  </div>
);

export default PageBanner;
