import FanDuelVan from '@/components/svg/FanDuelVan';

export type EmptyProps = {
  testId?: string;
  title: string;
  microcopy?: string;
  cta?: React.ReactElement;
};

const Empty = ({
  testId = 'empty-component',
  title,
  microcopy,
  cta,
}: EmptyProps) => {
  return (
    <div
      data-testid={testId}
      className='flex flex-col gap-y-2 justify-center items-center md:w-1/4 mx-auto'
    >
      <FanDuelVan alt='FanDuel Van' />
      <div className='text-lg leading-5'>{title}</div>
      <div className='leading-5 text-grey-d1'>{microcopy}</div>
      {cta && <div className='mt-2'>{cta}</div>}
    </div>
  );
};

export default Empty;
