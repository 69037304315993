'use client';

import ReleaseCard from '@/components/pressRoom/ReleaseCard';
import useSearchReleases from '@/utils/hooks/useSearchReleases';
import ErrorComponent from '@/components/empty/ErrorComponent';
import Loading from '@/components/common/loading/Loading';
import { FALLBACK_IMAGE } from '@/constants/media';
import { SortOrder } from '@/graphql/gql/graphql';

const ReleasesContainer = () => {
  const args = {
    where: {
      _: { is_draft: false },
    },
    limit: 9,
    sort: { datePublished: SortOrder.Desc },
  };

  const { data, isLoading, isError } = useSearchReleases(args);
  const releasesData = data ?? [];
  const aboveFold = releasesData.slice(0, 3);
  const belowFold = releasesData.slice(3);

  if (isError) {
    return (
      <div className='min-h-[400px] flex justify-center items-center'>
        <ErrorComponent />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='min-h-[400px] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-12'>
      {aboveFold.map((release) => (
        <ReleaseCard
          key={release._id}
          title={release.title ?? ''}
          date={release.datePublished ?? ''}
          slug={release.slug?.current ?? ''}
          imageUrl={release.image?.asset?.url ?? FALLBACK_IMAGE}
          priority
        />
      ))}
      {belowFold.map((release) => (
        <ReleaseCard
          key={release._id}
          title={release.title ?? ''}
          date={release.datePublished ?? ''}
          slug={release.slug?.current ?? ''}
          imageUrl={release.image?.asset?.url ?? FALLBACK_IMAGE}
          priority={false}
        />
      ))}
    </div>
  );
};

export default ReleasesContainer;
