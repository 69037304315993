import { useQuery } from '@tanstack/react-query';
import { GetPageHomepageQuery } from '@/graphql/gql/graphql';
import { getPageHomepage } from '../api';

const usePageHomepage = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['homepage'],
    queryFn: () => getPageHomepage(),
    select: (response: GetPageHomepageQuery) => {
      return {
        bannerUrl: response.allPrPage?.[0].banner?.asset?.url ?? '',
      };
    },
  });

  return { data, isError, isLoading };
};

export default usePageHomepage;
