'use client';

import ErrorComponent from '@/components/empty/ErrorComponent';
import Loading from '@/components/common/loading/Loading';
import usePageHomepage from '@/utils/hooks/usePageHomepage';
import PageBanner from '@/components/common/pageBanner/PageBanner';

const HomepageContainer = () => {
  const { data, isLoading, isError } = usePageHomepage();
  const bannerUrl = data?.bannerUrl ?? '';

  if (isError) {
    return (
      <div className='min-h-[400px] flex justify-center items-center'>
        <ErrorComponent />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='min-h-[400px] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  return (
    <div data-testid='homepage-container'>
      <PageBanner url={bannerUrl} alt='Homepage Banner' />
    </div>
  );
};

export default HomepageContainer;
