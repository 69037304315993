import Loader from '@/components/common/loader/Loader';

const Loading = () => {
  return (
    <div
      data-testid='loading-component'
      className='w-full min-h-[400px] flex justify-center items-center'
    >
      <Loader />
    </div>
  );
};

export default Loading;
