import Image from '@/components/common/image/Image';
import Link from '@/components/common/link/Link';
import { LONG_DATE } from '@/constants/dates';
import { formatDate } from '@/utils/date';

type ReleaseCardProps = {
  title: string;
  slug: string;
  date: string;
  priority?: boolean;
  imageUrl: string;
};

const ReleaseCard = ({
  title,
  date,
  imageUrl,
  priority,
  slug,
}: ReleaseCardProps) => {
  return (
    <Link next href={`/press-releases/${slug}`} prefetch={priority}>
      <div className='relative h-[208px] lg:h-[161px] xl:h-[208px]'>
        <Image
          src={imageUrl}
          alt={title}
          sizes='(min-width: 1440px) 33vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw'
          priority={priority}
          fill
          className='object-cover'
          quality={85}
        />
      </div>
      <h2 className='mt-3 text-lg lg:mt-4 lg:text-2xl font-bold line-clamp-2 text-marketing-link'>
        {title}
      </h2>
      <span className='mt-1 inline-block text-grey-d1 font-semibold'>
        {formatDate(date, LONG_DATE)}
      </span>
    </Link>
  );
};

export default ReleaseCard;
