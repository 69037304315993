import { useQuery } from '@tanstack/react-query';
import {
  SearchAllPrReleaseQueryVariables,
  SearchAllPrReleaseQuery,
} from '@/graphql/gql/graphql';
import { searchReleases } from '../api';

const useSearchReleases = ({
  where,
  sort,
  offset,
  limit,
}: SearchAllPrReleaseQueryVariables) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'search-releases',
      where?.slug?.current,
      where?.title,
      where?.datePublished?.gte,
      where?.datePublished?.lte,
      sort,
      offset,
      limit,
    ],
    queryFn: () => searchReleases({ where, sort, offset, limit }),
    select: (response: SearchAllPrReleaseQuery) => {
      return response.allPrRelease ?? [];
    },
  });

  return { data, isError, isLoading };
};

export default useSearchReleases;
