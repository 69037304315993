import Empty from './Empty';

const ErrorComponent = () => (
  <Empty
    testId='error-component'
    title='Content failed to load'
    microcopy='Please check back later.'
  />
);

export default ErrorComponent;
