import { format, parseISO } from 'date-fns';

export const formatReleaseDate = (date: string) =>
  format(parseISO(date), 'MMMM d, yyyy');

export const formatDate = (date: string, dateFormat: string) => {
  if (!date) {
    return null;
  }
  const parsed = parseISO(date);
  return format(parsed, dateFormat);
};
